<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>{{$t('i18nn_c4ac0560611963af')}}</span>
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('i18nn_c4ac0560611963af') }}</span>
						<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		
		<div class="filterCon" style="">
			<div class="filterBarList">
				
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->
					
				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span>{{$t('i18nn_80d0adba3c341069')}}</span>
						<el-select style="width: 100px;" filterable clearable v-model="filterData.wh_account_income_type" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" @change="initData()">
						  <el-option v-for="item in selectOption.wh_account_income_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li>
					<li>
						<span>{{$t('i18nn_6ab4144b540a8a6a')}}</span>
						<el-select style="width: 100px;" filterable clearable
							v-model="filterData.wh_account_change_type"
							:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" @change="initData()">
							<el-option v-for="item in selectOption.wh_account_change_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
									<!-- <span class="sel_option_code" style="">{{ item.code }}</span> -->
								</div>
							</el-option>
						</el-select>
					</li>
					<li>
						<span>{{$t('i18nn_46c3f6e0f657e7a3')}}</span>
						<el-select style="width: 100px;" filterable clearable v-model="filterData.wh_fee_type" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" @change="initData()">
						  <el-option v-for="item in selectOption.wh_fee_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li>
					<li>
						<span>{{$t('i18nn_1466b1690d2ae404')}}</span>
						<el-date-picker
							v-model="filterData.daterange"
							size="small"
							type="daterange"
							align="right"
							:clearable="true"
							unlink-panels
							range-separator="-"
							:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')"
							:picker-options="pickerOptions"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
							@change="initData()"
						></el-date-picker>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_80d0adba3c341069')}}</span>
				<el-select style="width: 100px;" filterable clearable v-model="filterData.wh_account_income_type" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" @change="initData()">
				  <el-option v-for="item in selectOption.wh_account_income_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_46c3f6e0f657e7a3')}}</span>
				<el-select style="width: 100px;" filterable clearable v-model="filterData.wh_fee_type" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" @change="initData()">
				  <el-option v-for="item in selectOption.wh_fee_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
					<span>{{$t('i18nn_1466b1690d2ae404')}}</span>
					<el-date-picker
						v-model="filterData.daterange"
						size="small"
						type="daterange"
						align="right"
						:clearable="true"
						unlink-panels
						range-separator="-"
						start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
						end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
						:picker-options="pickerOptions"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						@change="initData()"
					></el-date-picker>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con" v-if="!isSel"> -->
			<!-- <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button> -->
			<!-- <el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button> -->
		<!-- </div> -->
		
		 <!-- <el-alert
		    :title="$t('i18nn_daaaeb1b7b22b126')"
		    type="warning"
		    description="后台确认通过后,为已充值数据"
		    show-icon>
		  </el-alert> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" 
			size="small" :max-height="$store.state.frameConHeightWh5">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
				
				<!-- <el-table-column prop="verfiyStatusName" :label="$t('Storage.packing_list.status')">
				  <template slot-scope="scope">
				    <el-tag type="" v-if="'0' == scope.row.verfiyStatus">{{ $Utils.i18nKeyText(scope.row,'verfiyStatusName') }}</el-tag>
				    <el-tag type="success" v-else-if="'10' == scope.row.verfiyStatus">{{ $Utils.i18nKeyText(scope.row,'verfiyStatusName') }}</el-tag>
				    <el-tag type="warning" v-else-if="'20' == scope.row.verfiyStatus">{{ $Utils.i18nKeyText(scope.row,'verfiyStatusName') }}</el-tag>
				    <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'verfiyStatusName') }}</el-tag>
				  </template>
				</el-table-column> -->
				<el-table-column prop="incomeType" :label="$t('i18nn_80d0adba3c341069')">
				  <template slot-scope="scope">
				    <el-tag type="primary" v-if="'1'==scope.row.incomeType">{{ $Utils.i18nKeyText(scope.row,'incomeTypeName') }}</el-tag>
				    <el-tag type="warning" v-else-if="'2'==scope.row.incomeType">{{ $Utils.i18nKeyText(scope.row,'incomeTypeName') }}</el-tag>
				    <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'incomeTypeName') }}</el-tag>
				  </template>
				</el-table-column>
				<el-table-column prop="account" :label="$t('i18nn_169a0780408f1cce')"></el-table-column>
				<!-- <el-table-column prop="incomeTypeName" :label="$t('i18nn_80d0adba3c341069')"></el-table-column> -->
				<el-table-column prop="changeTypeName" :label="$t('i18nn_6ab4144b540a8a6a')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'changeTypeName') }}
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="changeAmt" :label="$t('i18nn_5706df6392c2b9ef')"></el-table-column>
				<el-table-column prop="feeTypeName" :label="$t('i18nn_46c3f6e0f657e7a3')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'feeTypeName') }}
						</div>
					</template>
				</el-table-column>
				

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="feeTime" :label="$t('i18nn_43a3586339251494')"></el-table-column>
				
				
				<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
						
					</template>
				</el-table-column> -->
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="$t('i18nn_7b7058faea31b99f')" append-to-body :visible.sync="dialogFormVisible" custom-class="myFullDialog4">
			<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 1000px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
				
				<el-form-item :label="$t('i18nn_a06e84801d6582be')" prop="amt">
					<el-input-number v-model="form.amt" style="width: 200px;"></el-input-number>
					<span class="form_msg">USD</span>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_8c49a4bfd3a78dc2')" prop="">
					<div>
						<RechargeRecordFileUpload ref="RechargeRecordFileUpload" :openTime="FileUploadOpenTime"  @success="FileUploadSuccess"></RechargeRecordFileUpload>
					</div>
				</el-form-item>

				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit style="width: 300px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitAction()">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhAccountRecordList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
// import JQ from 'jquery'
// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
// import RechargeRecordFileUpload from '@/components/StorageCenter/components/RechargeRecordFileUpload.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		//   CommodityCateLinkage,
		// barcode,
		// SelAgentUser
		// RechargeRecordFileUpload
		whExcelCustom
	},
	// props: {
	// 	// mobile:"",
	// 	isSel: {
	// 		default: function() {
	// 			return false;
	// 		},
	// 		type: Boolean
	// 	},
	// 	status: {
	// 		default: function() {
	// 			return '';
	// 		},
	// 		type: String
	// 	}
	// },
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			pickerOptions: this.$PickerDate.pickerOptions_1(),
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			//导入excel
			// dialogUploadVisible: false,
			// loadingExcel: false,
			// fileExcel: '',
			// excelData: [],
			// excelFileName: '',
			fileRelationId:'',
			FileUploadOpenTime:'',

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			// CateValue: [],
			loading: false,

			// form: {
			// 	amt: '', //this.$t('i18nn_a06e84801d6582be'),
			// 	evidenceList: [
			// 		// {
			// 		// 	fileName: '', //this.$t('i18nn_89221ad15d2ec113'),
			// 		// 	url: '' //this.$t('i18nn_8758fd50c87d6c9c')
			// 		// }
			// 	],
			// 	remark: ''
			// },

			// formRules: {
			// 	amt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	remark: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
			// },
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// wh_weight_unit: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type:[],
				wh_account_change_type:[],
				wh_size_unit: [],
				// statusList: [
				// 	{
				// 		value: '',
				// 		label: this.$t('i18nn_16853bda54120bf1')
				// 	},
				// 	{
				// 		value: '1',
				// 		label: this.$t('i18nn_fdd8426d7b8869c5')
				// 	},
				// 	{
				// 		value: '2',
				// 		label: this.$t('i18nn_619d7e563f879811')
				// 	},
				// 	{
				// 		value: '3',
				// 		label: this.$t('i18nn_1dfb3a065d3ffe39')
				// 	}
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				agentUser: '',
				// "accountPeriod":"",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				// goodsSku: '',
				// // hashCode: '',
				// goodsName: '',
				// goodsNameEn: '',
				keyword: '',
				
				wh_fee_type:'',
				wh_account_income_type:'',
				wh_account_change_type:'',
				// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
				daterange: [],
				// declareNameCh: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_fee_type','wh_account_income_type','wh_account_change_type'],
			(data)=>{
				this.selectOption.wh_fee_type = data['wh_fee_type'];
				this.selectOption.wh_account_income_type = data['wh_account_income_type'];
				this.selectOption.wh_account_change_type = data['wh_account_change_type'];
		});
		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		//导出 excel
		exportExcel() {
			
			// <el-table-column prop="account" :label="$t('i18nn_169a0780408f1cce')"></el-table-column>
			// <el-table-column prop="incomeTypeName" :label="$t('i18nn_80d0adba3c341069')"></el-table-column>
			// <el-table-column prop="changeTypeName" :label="$t('i18nn_cd173f918d077f02')"></el-table-column>
			
			// <el-table-column prop="changeAmt" :label="$t('i18nn_5706df6392c2b9ef')"></el-table-column>
			// <el-table-column prop="feeTypeName" :label="$t('i18nn_46c3f6e0f657e7a3')"></el-table-column>
			
			
			// <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>
			
			// <el-table-column prop="feeTime" :label="$t('i18nn_43a3586339251494')"></el-table-column>
			
			let columns = [
				{
					title: this.$t('i18nn_169a0780408f1cce'),
					key: 'account'
				},
		    {
		    	title: this.$t('i18nn_80d0adba3c341069'),
		    	key: 'incomeTypeName'
		    },
		    {
		    	title: this.$t('i18nn_6ab4144b540a8a6a'),
		    	key: 'changeTypeName'
		    },
		    {
		    	title: this.$t('i18nn_5706df6392c2b9ef'),
		    	key: 'changeAmt'
		    },
		    {
		    	title: this.$t('i18nn_46c3f6e0f657e7a3'),
		    	key: 'feeTypeName'
		    },
				{
					title: this.$t('Storage.skuInfo.explain'),
					key: 'remark'
				},
				{
					title: this.$t('i18nn_43a3586339251494'),
					key: 'feeTime'
				},
			];
			// <el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
			let Data = this.tableData;
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'expenseRecord';
			this.excelOption = {height: 20};
			this.excelOpenTime = new Date().getTime();
			// excelUtilsNew.exportExcel(columns, Data, 'expenseRecord',{height:20});
		},
		// changeAgentUser(val){
		// 	this.filterData.agentUser = val;
		// 	this.initData();
		// },

		// exportExcelAction(){

		// 	let columns = [
		// 		{
		// 			title: 'SKU',
		// 			key: 'goodsSku'
		// 		},
		// 	{
		// 		title: "SKU CODE",
		// 		key: 'goodsSkuImg',
		// 		types: 'image'
		// 	},
		// 		{
		// 			title: this.$t('Storage.skuInfo.Chinese_title'),
		// 			key: 'goodsName'
		// 		},
		// 		{
		// 			title: this.$t('Storage.skuInfo.English_title'),
		// 			key: 'goodsNameEn'
		// 		},
		// 		{
		// 			title: this.$t('Storage.skuInfo.unit'),
		// 			key: 'sizeUnitName'
		// 		},
		// 		{
		// 			title: this.$t('Storage.skuInfo.length'),
		// 			key: 'goodsLength'
		// 		},
		// 	{
		// 		title: this.$t('Storage.skuInfo.width'),
		// 		key: 'goodsWidth'
		// 	},
		// 	{
		// 		title: this.$t('Storage.skuInfo.height'),
		// 		key: 'goodsHeight'
		// 	},
		// 	{
		// 		title: this.$t('Storage.skuInfo.weight'),
		// 		key: 'goodsWeight'
		// 	},
		// 	{
		// 		title: this.$t('Storage.skuInfo.price'),
		// 		key: 'declarePrice'
		// 	},
		// 		// {
		// 		// 	title: this.$t('hytxs0000037'),
		// 		// 	key: 'totalStock',
		// 		// 	types: 'custom',
		// 		// 	formatter:function(row){
		// 		// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
		// 		// 			return row.placeStockTotal.totalStock
		// 		// 		} else {
		// 		// 			return 0;
		// 		// 		}

		// 		// 	}
		// 		// },

		// 	];
		// 	// let Data = this.tableDataCatch;
		// 	// return columns;
		// 	let Data = this.tableData;

		// 	let data_dom = JQ('#ex_table').find(".el-table__body");
		// 	let obj_key_img = {};
		// 	// console.log(data_dom.find("tr"));
		// 	data_dom.find("tr").each((index, domEle)=>{
		// 		// console.log("index",index);
		// 		let imgObj = JQ(domEle).find("td .cell img");
		// 		// console.log("imgObj",imgObj);
		// 		if(imgObj){
		// 			obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
		// 		}
		// 	})
		// 	// console.log("obj_key_img",obj_key_img);
		// 	Data.forEach(item=>{
		// 		item.goodsSkuImg = obj_key_img[item.goodsSku];
		// 	})
		// 	// console.log('Data',Data);
		// 	// return;
		// 	excelUtilsNew.exportExcel(columns, Data, 'wh_sku');
		// },
		
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		// openDioalog(formParm) {
		// 	// console.log(formParm);
		// 	this.dialogFormVisible = true;
		// 	let form = Object.assign({}, formParm);
		// 	console.log('form', form);
		// 	// // 重置
		// 	this.resetForm('form');
		// 	if (null === formParm) {
		// 		//新增

		// 		// this.form.weightUnit = '1';
		// 		// this.form.volumeUnit = '1';
		// 		// this.form.whFeeType = '1';

		// 		this.dialogFormStatus = 0;
		// 		// form.subUserId = null;
		// 		// form.userSubUserId = null;
		// 		// form.state = true;
		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	} else {
		// 		//修改
		// 		this.dialogFormStatus = 1;
		// 		// form.state = form.state === '0' ? true : false;
		// 		// this.form.goodsSubjectId = this.CateValue[0];
		// 		// this.CateValue = [form.goodsSubjectId];
		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	}
		// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		// 	//       type: 'warning',
		// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 	//     });
		// },
		// //打开编辑
		// openEdit(event, row, index) {
		// 	event.stopPropagation();
		// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
		// },

		//删除
		// delAction(event, row) {
		// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
		// 		// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		// 		type: 'warning'
		// 	})
		// 		.then(() => {
		// 			// this.$message({
		// 			//   type: 'success',
		// 			//   message: '删除成功!'
		// 			// });
		// 			this.delDataAction(event, row);
		// 		})
		// 		.catch(() => {
		// 			// this.$message({
		// 			//   type: 'info',
		// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
		// 			// });
		// 		});
		// },
		// //删除
		// delDataAction(event, row) {
		// 	event.stopPropagation();
		// 	console.log('delDataAction', row);

		// 	this.postData(this.$urlConfig.WhRechargeVerifyDelete + '/' + row.id, {}, 'put');
		// },

		// hyUpLoadImg1: function(childValue) {
		//   // childValue就是子组件传过来的值
		//   this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },
		//商品类目变化
		// CateChang(v) {
		//   // this.onChangeNoSave(true);

		//   this.CateValue = v;

		//   this.form.goodsSubjectId = this.CateValue[0];

		//   // this.baseInfo.commodityCategories.first = this.CateValue[0];
		//   // this.baseInfo.commodityCategories.second = this.CateValue[1];
		//   // this.baseInfo.commodityCategories.third = this.CateValue[2];

		//   //该类目商品基本属性
		//   // this.getCommodityAttribute();
		// },
		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		// },
		//附件上传成功回调
		// FileUploadSuccess(data){
		// 	console.log('FileUploadSuccess',data);
			
		// 	this.form.evidenceList = data.map(item=>{
		// 		return {
		// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
		// 			url: item.filePath//this.$t('i18nn_8758fd50c87d6c9c')
		// 		}
		// 	});
			
		// 	this.submitForm('form');
			
		//   // this.initData();
		//   // event.stopPropagation();
		//   // this.isShowFileUpload = true;
		//   // this.fileRelationId = row.id;
		//   // this.FileUploadOpenTime = new Date().getTime();
		// },
		//提交
		// submitAction(){
			
		// 	this.$nextTick(() => {
		// 	  try {
		// 	    this.$refs.RechargeRecordFileUpload.submitUpload();
		// 	  } catch (err) {
		// 	    console.log(err);
		// 	  }
		// 	});
		// },
		// //提交信息
		// submitForm(formName) {
		// 	this.$refs[formName].validate(valid => {
		// 		if (valid) {
		// 			let formData = Object.assign({}, this.form);
		// 			//浅拷贝、对象属性的合并
		// 			if (0 === this.dialogFormStatus) {
		// 				formData.id = null;
		// 				// formData.userId = this.UserInfo.id;

		// 				this.postData(this.$urlConfig.WhRechargeVerifyUpdate, formData);
		// 			} else {
		// 				// formData.userId = this.UserInfo.id;
		// 				this.postData(this.$urlConfig.WhRechargeVerifyUpdate, formData);
		// 			}
		// 		} else {
		// 			console.log('error submit!!');
		// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			});
		// 			return false;
		// 		}
		// 	});
		// },
		// //重置输入框
		// resetForm(formName) {
		// 	console.log(formName);
		// 	// console.log(this.$refs[formName]);

		// 	if (this.$refs[formName]) {
		// 		this.$refs[formName].resetFields();
		// 	} else {
		// 		console.log('this.$refs[formName]', this.$refs[formName]);
		// 	}
			
		// 	this.$nextTick(() => {
		// 	  try {
		// 	    this.$refs.RechargeRecordFileUpload.initData();
		// 	  } catch (err) {
		// 	    console.log(err);
		// 	  }
		// 	});
		// 	this.form.evidenceList = [];
		// 	this.form.amt = 0;
		// 	// this.CateValue = [];
		// 	// if (this.$refs.hyUpLoadImg1) {
		// 	//   this.$refs.hyUpLoadImg1.initUrl('');
		// 	// }
		// },

		//提交信息
		// postData(url, formData, type) {
		// 	// let _this = this;
		// 	this.loading = true;
		// 	this.loading_load = true;
		// 	let HttpType = {};
		// 	if ('delete' == type) {
		// 		HttpType = this.$http.delete(url, formData);
		// 	} else {
		// 		HttpType = this.$http.put(url, formData);
		// 	}
		// 	// formData.state = formData.state ? '0' : '1';
		// 	HttpType.then(({ data }) => {
		// 		console.log(this.$t('tips.submitSuccess'));
		// 		console.log(data);
		// 		
		// 		this.loading = false;
		// 		this.loading_load = false;
		// 		if (200 == data.code) {
		// 			this.dialogFormVisible = false;
		// 			this.getPageData();
		// 			// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
		// 			//   type: 'success',
		// 			//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			// });
		// 			// this.$message.success(this.$t('tips.submitSuccess'));
		// 			if ('delete' == type) {
		// 				this.$message({
		// 					type: 'success',
		// 					message: this.$t('tips.successfullyDelete')
		// 				});
		// 			} else {
		// 				// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
		// 				// 	type: 'success'
		// 				// });
		// 				this.$message.success(this.$t('tips.submitSuccess'));
		// 			}
		// 		} else {
		// 			// if (!data.msg) {
		// 			//   data.msg = this.$t('tips.submitError');
		// 			// }
		// 			this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			});
		// 		}
		// 	}).catch(error => {
		// 		console.log(error);
		// 		console.log(this.$t('tips.submitError'));
		// 		this.loading = false;
		// 		this.loading_load = false;
		// 		this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
		// 			type: 'warning'
		// 			//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		});
		// 	});
		// },
		// clearFile(){
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	// this.excelFileName = "";
		// 	this.$nextTick(() => {
		// 		try {
		// 			this.$refs.file.value = '';
		// 		} catch (e) {
		// 			console.log(e);
		// 			//TODO handle the exception
		// 		}
		// 	});
		// },
		//打开导入弹窗
		// openExcelDialog(){
		//   this.dialogUploadVisible = true;
		//   this.excelData = [];
		//   this.clearFile();
		// },
		// fileChange(e) {
		//   // this.loading = true;
		//   console.log(e);

		//   console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
		//   this.excelData = [];
		//   this.fileExcel = null;
		//   this.excelFileName = "";
		//   if (!this.$refs.file || !this.$refs.file.files) {
		//     this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
		//       // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     });
		//     return;
		//   }
		//   if (this.$refs.file.files[0]) {
		//     // if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
		//       if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
		//         this.fileExcel = this.$refs.file.files[0];
		//         this.excelFileName = this.fileExcel.name;
		//         // console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
		//         // this.uploadExcel();
		//       } else {
		//         this.$alert(this.$t('module.upload.uploaded_over')+'10M', this.$t('tips.tipsTitle'), {
		//         });
		//       }
		//     // } else {
		//     //   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
		//     //   });
		//     // }
		//   } else {
		//     // this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
		//     // });
		//   }
		// },

		//  openExcel(){
		// // this.initExcelData();
		//    // this.$nextTick(()=>{
		//      try{
		//        this.$refs.file.value = '';
		//      }catch(e){
		//        console.log(e);
		//        //TODO handle the exception
		//      }
		//    // });
		//    // this.$refs.file.dispatchEvent(new MouseEvent('click'));
		//    var comment = this.$refs.file;
		//    if (document.all) {
		//      // For IE
		//      comment.click();
		//    } else if (document.createEvent) {
		//      // 在ff中要为a标签添加click事件，并且侦听该事件
		//      var ev = document.createEvent('MouseEvents');
		//      ev.initEvent('click', false, true);
		//      comment.dispatchEvent(ev);
		//    } else {
		//      // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
		//      this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
		//        // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//      });
		//    }
		//  },
		//导入excel
		// uploadExcel(){
		//   if(!this.fileExcel){
		//     this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {
		//     });
		//     return;
		//   }

		//   this.loadingExcel = true;

		//   let file = this.fileExcel;
		//   var formdata = new FormData();
		//   formdata.append("file",file);

		//   this.$http.post(this.$urlConfig.WhMyGoodsSkuExUpload, formdata,{
		//     headers: {
		//       'Content-Type': 'multipart/form-data'
		//     }
		//   })
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       this.loadingExcel = false;
		//       if (200 == data.code && data.rows) {
		//         this.excelData = data.rows;
		//         // if('1'==data.data.flag){
		//         //   this.excelData = data.data.data;
		//         // } else {
		//         //   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//         //     type: 'warning',
		//         //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         //   });
		//         // }
		// 		this.clearFile();
		//       } else {
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       }
		//       // if (200 == data.code && data.data) {
		//       //   if('1'==data.data.flag){
		//       //     this.excelData = data.data.data;
		//       //   } else {
		//       //     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//       //       type: 'warning',
		//       //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       //     });
		//       //   }

		//       // } else {
		//       //   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//       //     type: 'warning',
		//       //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       //   });
		//       // }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loadingExcel = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//     });
		// },
		//提交-导入excel
		// submitExcelForm(){
		//   this.loadingExcel = true;
		//   this.$http.put(this.$urlConfig.WhMyGoodsSkuExSubmit, this.excelData)
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       this.loadingExcel = false;
		//       if (200 == data.code && data.data) {
		//         if('1'==data.data.flag){
		//           // this.excelData = data.data.data;
		//           this.dialogUploadVisible = false;
		//           this.excelData = [];
		//           this.excelFileName = "";
		//           this.fileExcel = null;

		//           this.initData();
		//           // this.$alert(this.$t('i18nn_b93f7c8c4e2346f8'), this.$t('tips.tipsTitle'), {
		//           //   type: 'success',
		//           //   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//           // });
		//           this.$message.success(this.$t('tips.submitSuccess'));
		//         } else {
		//           this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//             type: 'warning',
		//             //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//           });
		//         }

		//       } else {
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loadingExcel = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//     });
		// },
		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},
		//商品类目变化
		// CateChang2(v) {
		//   // this.onChangeNoSave(true);

		//   // this.CateValue = v;

		//   this.filterData.subjectName = this.CateValue[0];
		//   this.serPageData();
		//   // this.baseInfo.commodityCategories.first = this.CateValue[0];
		//   // this.baseInfo.commodityCategories.second = this.CateValue[1];
		//   // this.baseInfo.commodityCategories.third = this.CateValue[2];

		//   //该类目商品基本属性
		//   // this.getCommodityAttribute();
		// },
		//分页的筛选项数据
		pageFilterData() {
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				// this.$message.warning(this.$t('tips.Please_Sel_date'));
			}
			return {
				sortAsc: this.filterData.sortAsc,
				orderBy: this.filterData.orderBy,
				// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				//      // status: this.filterData.status ? this.filterData.status : null,
				"feeType": this.filterData.wh_fee_type? this.filterData.wh_fee_type:null,
				incomeType: this.filterData.wh_account_income_type ? this.filterData.wh_account_income_type : null,
				changeType: this.filterData.wh_account_change_type ? this.filterData.wh_account_change_type : null,
				
				startFeeTime: startTime ? startTime : null,
				endFeeTime: endTime ? endTime : null,
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				},
				this.pageFilterData()
			);
			
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhAccountRecordList, filterData)
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
		//   console.log(row);
		//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		selRow(event, row) {
			event.stopPropagation();
			this.$emit('selectRow', row);
		},
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_fee_type','wh_account_income_type','wh_account_change_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_fee_type = data.data['wh_fee_type'];
		// 				this.selectOption.wh_account_income_type = data.data['wh_account_income_type'];
		// 				this.selectOption.wh_account_change_type = data.data['wh_account_change_type'];
						
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
